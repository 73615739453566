


























import { sortArray } from "@/helpers/arrayHelper";
import { Component, Prop, Vue } from "vue-property-decorator";
import SharedGalleryListItem from "./ListItem.vue";

@Component({
    components: {
        SharedGalleryListItem,
    },
})
export default class SharedGalleryList extends Vue {
    @Prop() gallery!: any;

    get sortedFunds() {
        if (!this.gallery) return [];

        let funds: any[] = this.gallery.funds;
        let sortedFunds: any[] = sortArray(funds, "created_at", true);

        return sortedFunds;
    }

    get isGalleryExists() {
        let isExists: boolean = false;

        if (this.gallery && this.gallery.funds && this.gallery.funds.length)
            isExists = true;

        return isExists;
    }
}
